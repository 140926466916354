#contact label { display: inline-block; float: left; height: 26px; line-height: 26px; width: 155px; font-size: 1.5em; -webkit-border-radius:5px; }
#contact input, textarea, select { width: 280px; margin: 0; padding: 5px; color: #666; background: #fff; border: 1px solid #ccc; margin: 5px 0; font: "Lucida Grande", "Lucida Sans Unicode", Arial, sans-serif; webkit-border-radius:7px; border-radius:7px; }
#contact input:focus, textarea:focus, select:focus { border: 1px solid #999; background-color: #fff; color:#333; }
#contact input.submit { width: 85px; cursor: pointer; border: 1px solid darken(@brand-primary, 20%); background:@brand-primary; color:#fff; }
#contact input.submit:hover { background:darken(@brand-primary, 20%); }
#contact input[type="submit"][disabled] { background:#888; }
#contact fieldset { -webkit-border-radius:5px; -moz-border-radius:5px; }
#contact legend { padding:7px 10px; font-weight:bold; color:#000; border:1px solid #eee; -webkit-border-radius:5px; -moz-border-radius:5px; margin-bottom:0 !important; margin-bottom:20px; }

#contact span.required{ font-size: 13px; color: #ff0000; } /* Select the colour of the * if the field is required. */

#message { margin: 10px 0; padding: 0; }

.error_message { display: block; height: 22px; line-height: 22px; background: #FBE3E4 url('../../assets/error.gif') no-repeat 10px center; padding: 8px 10px 30px 35px; color:#8a1f11;border: 1px solid #FBC2C4; -webkit-border-radius:5px; }

.loader { padding: 0 10px; }

#contact #success_page h1 { background: url('../../assets/success.gif') left no-repeat; padding-left:22px; }

acronym { border-bottom:1px dotted #ccc; }

#contact { text-align: left; }

#contact input {
    width: 100%;
}

#contact #submit {
    float: right;
    position: relative;
    top: -40px;
}