.options-tab {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 90%;
    height: 70px;
    padding-top: 20px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    background: #b3b3b3;
    background: -webkit-gradient(linear, left top, left bottom, from(#b3b3b3), to(#999797));
    background: -moz-linear-gradient(top, #b3b3b3, #999797);
    background: linear-gradient(to bottom, #b3b3b3, #999797);
    text-shadow: #591717 1px 1px 1px;
    font: 19px verdana;
    color: #ffffff;
    text-decoration: none;
}
.options-tab:hover,
.options-tab:focus {
    background: #d7d7d7;
    background: -webkit-gradient(linear, left top, left bottom, from(#d7d7d7), to(#b8b5b5));
    background: -moz-linear-gradient(top, #d7d7d7, #b8b5b5);
    background: linear-gradient(to bottom, #d7d7d7, #b8b5b5);
    color: #ffffff;
    text-decoration: none;
}
.options-tab:active {
    background: #6b6b6b;
    background: -webkit-gradient(linear, left top, left bottom, from(#6b6b6b), to(#999797));
    background: -moz-linear-gradient(top, #6b6b6b, #999797);
    background: linear-gradient(to bottom, #6b6b6b, #999797);
}





.back-button {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 6px 14px;
    border: 1px solid #fff;
    border-radius: 8px;
    background: #b3a6a6;
    background: -webkit-gradient(linear, left top, left bottom, from(#b3a6a6), to(#917f7f));
    background: -moz-linear-gradient(top, #b3a6a6, #917f7f);
    background: linear-gradient(to bottom, #b3a6a6, #917f7f);
    text-shadow: #591717 1px 1px 1px;
    font: normal normal bold 15px arial;
    color: #ffffff;
    text-decoration: none;
}
.back-button:hover,
.back-button:focus {
    border: 1px solid #fff;
    background: #d7c7c7;
    background: -webkit-gradient(linear, left top, left bottom, from(#d7c7c7), to(#ae9898));
    background: -moz-linear-gradient(top, #d7c7c7, #ae9898);
    background: linear-gradient(to bottom, #d7c7c7, #ae9898);
    color: #ffffff;
    text-decoration: none;
}
.back-button:active {
    background: #6b6464;
    background: -webkit-gradient(linear, left top, left bottom, from(#6b6464), to(#917f7f));
    background: -moz-linear-gradient(top, #6b6464, #917f7f);
    background: linear-gradient(to bottom, #6b6464, #917f7f);
}



.footer-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.bgImg {
    min-height: 100%;
    width: 100%;
    background-image: url(../img/bg/home2.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    z-index: -999;
    opacity: 0.5;
}

.main-content {
    width: 100%;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 100px;
}

.logo {
    width: 700px;
    display: inline !important;
}

.content {
    padding: 20px 10px 10px 10px;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}

@media screen and (max-width: 500px) {
    body {
        overflow: scroll;
    }
}

.bg-trans {
    background-color: rgba(256,256,256,0.75);
}

.range {
	width: 200px;
	height: 400px;
	padding: 10px;
	margin: 10px;
	float: left;

	border-radius: 2px;
	border-style: solid;
	border-width: 1px;
	border-color: darken(@body-bg, 10%);

	transition: all .2s;
	-webkit-transition: all .2s;
	-moz-transition: all .2s;
	-o-transition: all .2s;
	-ms-transition: all .2s;
}

.range img {
	border-radius: 2px;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);

	display: block;
	margin: 0 auto;
}

.range p {
	font-size: 12px;
}
